import type { AnyAction } from 'redux';
import * as Sentry from '@sentry/react';
import type { Breadcrumb, Context as SentryContext, Primitive, User } from '@sentry/types';

import sentryDsn from 'js/sentry.json';
import { client } from 'js/src/client';
import { FREE_PLAN_DISPLAY_NAME } from 'js/src/libs/consts';
import type { Context, ReleaseStage } from 'js/src/libs/error-reporter';
import { Project } from 'js/src/libs/error-reporter';
import { replayCanvasIntegration } from 'js/src/libs/lumen5-replay-canvas-integration';
import { isOnMobileDevice } from 'js/src/libs/mobile-check';
import type { RootState } from 'js/src/libs/RootState';
import { isApiError } from 'js/src/types';


function getDSN(project: Project) {
    switch (project) {
        case Project.FRONTEND:
            return sentryDsn.frontend;
        case Project.LUMINARY:
            return sentryDsn.luminary;
    }
}

/**
 * Free users use our platform at a much higher rate than paid users, so we want to have different sample rates
 * so that the volume of free users doesn't drown out the paid users.
 */
function getReplaySampleRate() {
    if (SERVER && SERVER.USER && SERVER.USER.plan_display_name !== FREE_PLAN_DISPLAY_NAME) {
        return 0.06;
    }
    return 0.003;
}

/**
 * Initializes the global Sentry instance.
 * Should be called only once per page load.
 */
export function init(project: Project, releaseStage: ReleaseStage) {
    // https://docs.sentry.io/platforms/javascript/best-practices/micro-frontends/
    const EXTRA_KEY = 'ROUTE_TO';

    const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, (args) => {
        const event = args.getEvent();
        if (
            event &&
            event.extra &&
            EXTRA_KEY in event.extra &&
            Array.isArray(event.extra[EXTRA_KEY])
        ) {
            return event.extra[EXTRA_KEY] as string[];
        }
        return [];
    });

    Sentry.init({
        dsn: releaseStage === 'production' ? getDSN(project) : undefined,
        transport,
        environment: releaseStage,
        release: SERVER.VERSION || 'unknown',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                networkDetailAllowUrls: [
                    // Capture all network requests to our domain
                    window.location.origin,
                ],
                networkDetailDenyUrls: [
                    // Don't capture info on our auth urls
                    /\/auth\//,
                ],
                networkRequestHeaders: ['X-LUMEN5-WORKSPACE'],
            }),
            replayCanvasIntegration({
                enableManualSnapshot: true,
                recordCanvas: false,
            }),
            Sentry.moduleMetadataIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                // should match the sentry application keys in webpack.config.prod.js
                filterKeys: ['lumen5-frontend', 'lumen5-luminary'],
                // in Sentry we can filter out these tagged errors
                // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
                behaviour: 'apply-tag-if-contains-third-party-frames',
            }),
        ],

        // Since we collect our own traces in our infra and backend code, these frontend traces don't
        // currently connect with the rest of the traces. So we set the sample rate to be quite low, but still
        // non-zero so we can get a bit of frontend insight.
        tracesSampleRate: 0.001,
        profilesSampleRate: 0.01, // relative to tracesSampleRate

        replaysSessionSampleRate: getReplaySampleRate(),
        replaysOnErrorSampleRate: 1.0,

        // Useful in particular for the redux state enhancer
        normalizeDepth: 10,

        beforeSend: (event) => {
            if (isOnMobileDevice()) {
                return null;
            }
            const error = event.exception?.values?.[0];
            if (error?.stacktrace?.frames) {
                const frames = error.stacktrace.frames;
                if (!frames || !frames.length) {
                    return event;
                }
                if (frames[0].filename?.includes('<anonymous>')) {
                    // We're pretty sure that if the first frame is <anonymous>, it's from the console
                    // (a developer triggered it) or an extension. We don't want to capture those.
                    return null;
                }

                // For the frontend project, there's shared code ownership with luminary and so to route correctly
                // we use the first stack frame with module metadata containing a DSN (comes from our webpack build)
                if (project === Project.FRONTEND) {
                    const routeTo = frames
                        .filter(frame => frame.module_metadata && frame.module_metadata.dsn)
                        .map(v => v.module_metadata)
                        .slice(-1);

                    if (routeTo.length) {
                        event.extra = {
                            ...event.extra,
                            [EXTRA_KEY]: routeTo,
                        };
                    }
                }
            }

            // if there's the global luminary instance attach the number of scenes to the event
            if (_luminary_data && _luminary_data.videoStore && _luminary_data.videoStore.scenes) {
                event.extra = {
                    ...event.extra,
                    luminary_scene_count: _luminary_data.videoStore.scenes.length,
                    luminary_duration: _luminary_data.videoStore.duration,
                };
            }

            return event;
        },
    });

    // sentry polyfills fetch. We need to update the fetch that our api client uses so that
    // api calls are captured by sentry for the network tab in replays.
    client.setConfig({
        fetch: fetch,
    });
}

export function createReduxEnhancer() {
    return Sentry.createReduxEnhancer({
        // This will upload the entire redux state as a json attachment to errors
        // If we find the redux integration helpful we can enable this, but note that it will count towards
        // our attachment quota in sentry (which isn't that costly, but we will pay a bit for it)
        attachReduxState: false,
        stateTransformer(state: RootState | undefined) {
            // remove undoable and templates from the state, as they are quite large
            // (and templates are relatively static anyway)
            const { undoable, templates, app, ...rest } = state || {};
            const returnVal: Record<string, unknown> = rest;
            // remove app.publicBlueprints from the state, as it is quite large
            if (app) {
                const { publicBlueprints, ...appRest } = app;
                returnVal.app = appRest;
            }
            return returnVal;
        },
        actionTransformer(action: AnyAction): AnyAction | null {
            // remove the payload from actions, as we can have quite large payloads
            const { payload, ...rest } = action;
            return rest;
        },
    });
}

export function setUser(user: User) {
    Sentry.setUser(user);
}

export function getUser() {
    return Sentry.getIsolationScope().getUser();
}

export function setTag(key: string, value: Primitive) {
    Sentry.setTag(key, value);
}

export function capture(error: unknown, context?: Context) {
    // use default severity level of 'error'
    context = context || {};
    if (!context.level) {
        context.level = 'error';
    }

    if (isApiError(error)) {
        // add all of the API error information as context
        if (!context) {
            context = {};
        }
        if (!context.extra) {
            context.extra = {};
        }
        context['extra']['request_url'] = error.url;
        context['extra']['response_status'] = error.status;
        context['extra']['response_body'] = error.body;
    }

    if (typeof error === 'string') {
        Sentry.captureMessage(error, context);
    }
    else {
        Sentry.captureException(error, context);
    }
}

export function addBreadcrumb(breadcrumb: Breadcrumb) {
    Sentry.addBreadcrumb(breadcrumb);
}

export function setContext(name: string, context: SentryContext) {
    Sentry.setContext(name, context);
}

export async function sentryCanvasSnapshot(canvas: HTMLCanvasElement) {
    try {
        const canvasReplay = Sentry.getClient()?.getIntegrationByName<ReturnType<typeof replayCanvasIntegration>>('ReplayCanvas');
        if (canvasReplay) {
            await canvasReplay.snapshot(canvas);
        }
    }
    catch (e) {
        Sentry.captureException(e);
    }
}

export const startSentryReplay = (tag?: string) => {
    /**
     * Mark this session as one where a replay should be started.
     * Pass in a tag to help identify the session in Sentry.
     */
    const replay = Sentry.getReplay();
    if (replay === undefined) {
        capture('Replay not available');
        return;
    }
    if (!replay.getReplayId()) {
        replay.start();
    }

    if (tag) {
        Sentry.setTag(tag, 'true');
    }

    void replay.flush({ continueRecording: true });
};
